// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';
import { AuthService } from '../../pages/auth/auth.service';

@Component({
	selector: 'kt-footer',
	templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	fluid: boolean;
	brandLink: string;
	brandTitle: string;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayouConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
		private authService: AuthService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();

		// footer width fluid
		this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
		this.brandLink = 'https://arthimpact.in/';
		this.brandTitle = 'ARTH';
		// this.checkBrandLink();
	}

	checkBrandLink() {
		const user = this.authService.user;
		if (user) {
			const config = this.layoutConfigService.getConfig();
			this.brandLink = 'https://arthimpact.in/';
			this.brandTitle = 'ARTH';
		}
	}

}
