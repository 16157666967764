// angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../../environments/environment';

// models

@Injectable({
	providedIn: 'root'
})
export class PartnerService {

	// tslint:disable-next-line:max-line-length
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;
	private apiBaseUrl = environment.urls.api.base;
	private oldBaseUral= environment.urls.api.old_url

	constructor(private http: HttpClient) {
	}

	getPartners(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.partners + '/_search?sort=t:desc&size=10000&filter_path=hits.hits.*,-hits.hits._source.infos-hits,.hits._source.versions');
		let body = {
			index: environment.urls.elastic.indices.partners,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: ['*', 'infos-hits', 'versions'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getNewPartner(search_text,page,filters){
		let body={
			'search_text':search_text,
			"page_number": page.page_number,
			"per_page": page.per_page,
			'filters':filters
		}
		return this.http.post(this.apiBaseUrl + 'elastic/partners/all', body, this.esHeaders());
	}

	getPartner(partnerId: string): Observable<any> {
		let body={
				"partner_id":partnerId
		}
		  return this.http.post(this.apiBaseUrl + 'elastic/partners/details', body, this.esHeaders());

		  
	}

	getPartnerByCode(partnerCode: string): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.partners + '/_search?sort=t:desc&filter_path=hits.hits.*,-hits.hits._source.infos&q=code:' + partnerCode);
		// let body = {
		// 	index: environment.urls.elastic.indices.partners,
		// 	body: {
		// 	  query: {
		// 		match_phrase: { code: partnerCode },
		// 	  },
		// 	  sort: [],
		// 	  aggs: {
		// 		total_count: {
		// 		  value_count: {
		// 			field: '_id',
		// 		  },
		// 		},
		// 	  },
		// 	  user: {},
		// 	},
		// 	_source: ['*', 'infos'],
		// 	size: 10000,
		// 	from: 0,
		// 	filterPath: [],
		// 	sort: ['t:desc'],
		//   };
		//   return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
		  return this.http.get(this.oldBaseUral + 'elastic/partners/find_by_code?partner_code='+partnerCode,this.esHeaders())
	}

	putPartner(postData: any): Observable<any> {
		console.log(postData);
		return this.http.put(this.apiBaseUrl + 'partners/' + postData.id, postData, this.esHeaders());
	}

	getPartnerCodes(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.partners + '/_search?sort=code:desc&size=10000&filter_path=hits.hits._source.uid,hits.hits._source.code');
		let body = {
			index: environment.urls.elastic.indices.partners,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: ['uid', 'code'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getPartnerNames(): Observable<any> {
		return this.http.get(this.apiBaseUrl+'elastic/partners/partners',this.esHeaders())
	}
	
	postPartner(postData: any): Observable<any> {
		console.log(postData);
		return this.http.post(this.apiBaseUrl + 'partners', postData, this.esHeaders());
	}

	public verifyPartner(id: number): Observable<any> {
		return this.http.patch(this.apiBaseUrl + 'partners/' + id + '/verify', {},this.esHeaders());
	}

	public updateholdRepayment(id: number): Observable<any> {
		return this.http.patch(this.apiBaseUrl + 'partners/' + id + '/hold_repayment', {},this.esHeaders());
	}

	postBankAccount(postData: any): Observable<any> {
		console.log(postData);
		return this.http.post(this.apiBaseUrl + 'bank_accounts', postData,this.esHeaders());
	}

	putBankAccount(postData: any): Observable<any> {
		console.log(postData);
		return this.http.patch(this.apiBaseUrl + 'partners/' + postData.partner_id + '/current_bank_account', postData,this.esHeaders());
	}

	getDisbursalLimits(id: any): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.disbursal_limits + '/_search?q=partner_code:' + id);
		let body={
			"partner_code": id
		}

		  return this.http.post(this.apiBaseUrl + 'elastic/partners/disbursal_limit', body, this.esHeaders());

	}

	postDisbursalLimits(postData: any): Observable<any> {
		console.log(postData);
		postData.log_prefix = 'Put Disbursal Limit';
		postData.ai_env = environment.env;
		return this.http.post(this.apiBaseUrl + 'disbursal_limits', postData,this.esHeaders());
	}

	putDisbursalLimits(postData: any): Observable<any> {
		console.log(postData);
		postData.log_prefix = 'Put Disbursal Limit';
		postData.ai_env = environment.env;
		return this.http.put(this.apiBaseUrl + 'disbursal_limits/' + postData.id, postData,this.esHeaders());
	}

	getBankAccounts(id: any): Observable<any> {
		let body={
			"partner_id": id
		}
		// tslint:disable-next-line:max-line-length
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.bank_accounts + '/_search?q=account_holder_id:' + id);
		  return this.http.post(this.apiBaseUrl + 'elastic/partners/bank_accounts', body, this.esHeaders());

	}

	getSpocs(id: string): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.spocs + '/_search?sort=t:desc&size=10000&q=partner_id:' + id);
		let body={
			"partner_id": id
		}
		  return this.http.post(this.apiBaseUrl + 'elastic/partners/spocs', body, this.esHeaders());

		  
	}

	verify(partner_id, mapping_id): Observable<any> {
		const body = {
			partner_id,
			product_mapping_id: mapping_id
		};
		return this.http.patch(this.apiBaseUrl + 'partners/' + partner_id + '/activate_product_mapping', body,this.esHeaders());
	}

	blacklistPincode(partner_id, mapping_id): Observable<any> {
		const body = {
			partner_id,
			product_mapping_id: mapping_id
		};
		return this.http.post(this.apiBaseUrl + 'partners/' + partner_id + '/activate_pincode_blacklisting', body,this.esHeaders());
	}

	postSpoc(postData: any): Observable<any> {
		return this.http.post(this.apiBaseUrl + 'spocs', postData, this.esHeaders());
	}

	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return {headers};
	}

	getPaymentAdvice(partner_code, date): Observable<any> {
		return this.http.get(this.apiBaseUrl + 'partners/' + partner_code + '/get_payment_advice/' + date,this.esHeaders());
	}

	getAddonsList(){
	return this.http.get(this.apiBaseUrl +'v2/addons',this.esHeaders())
	}
}
