import { title } from "process";

export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: "Dashboards",
					root: true,
					alignment: "left",
					page: "/dashboard",
					translate: "MENU.DASHBOARD",
				},
				{
					title: "Customers",
					root: true,
					alignment: "left",
					page: "/customer",
					permissions: ["VIEW_CUSTOMER_LIST"],
				},
				{
					title: "Loans",
					root: true,
					alignment: "left",
					permissions: ["VIEW_LOAN_LIST"],
					submenu: [
						{
							title: "Verify Loans",
							page: "/loan/kyc",
							permissions: ["KYC_VERIFY_REJECT"],
						},
						// {
						// 	title: 'Approve Loans',
						// 	page: '/loan/kyc-verified',
						// 	permissions: ['KYC_APPROVE_REJECT']
						// },
						{
							title: "Approve Disbursal",
							page: "/loan/approve-reject",
							permissions: ["KYC_APPROVE_REJECT"],
						},
						{
							title: "All Loans",
							page: "/loan",
							permissions: ["VIEW_LOAN_LIST"],
						},
					],
				},
				{
					title: "Reports",
					root: true,
					alignment: "left",
					permissions: ["VIEW_DISBURSEMENT_REPORTS", "VIEW_REPORT_REPAYMENT", "VIEW_REPORT_RECON", "VIEW_REPORT_INTREST", "VIEW_REPORT_CRIF"],
					submenu: [
						// {
						// 	title: 'Disbursement Report',
						// 	page: '/report/disbursements',
						// 	icon: 'flaticon-presentation-1',
						// 	permissions: ['VIEW_DISBURSEMENT_REPORTS']
						// },
						// {
						// 	title: 'Repayment Report',
						// 	page: '/report/repayments',
						// 	icon: 'flaticon-presentation-1',
						// 	permissions: ['VIEW_REPORT_REPAYMENT']
						// },
						/* {
							title: 'Recon Report',
							page: '/report/recons',
							icon: 'flaticon-presentation-1',
							permissions: ['VIEW_REPORT_RECON']
						}, */
						// {
						// 	title: 'Interest Report',
						// 	page: '/report/interest',
						// 	icon: 'flaticon-presentation-1',
						// 	permissions: ['VIEW_REPORT_INTREST']
						// },
						// {
						// 	title: 'CKYC Report',
						// 	page: '/report/crif',
						// 	icon: 'flaticon-presentation-1',
						// 	permissions: ['VIEW_REPORT_CRIF']
						// },
						{
							title: "CKYC Report",
							page: "/report/ckyc",
							icon: "flaticon-presentation-1",
							// permissions: ['VIEW_REPORT_CRIF']
						},
					],
				},
				{
					title: "Admin",
					root: true,
					alignment: "right",
					permissions: ["VIEW_PARTNER"],
					submenu: [
						{
							title: "Partners",
							page: "/admin/partners",
							icon: "flaticon-network",
							permissions: ["VIEW_PARTNER"],
						},
						{
							title: "Lenders",
							page: "/admin/lenders",
							icon: "flaticon-network",
							permissions: ["VIEW_LENDER"],
						},
						{
							title: "Tncs",
							page: "/admin/tncs",
							icon: "flaticon-network",
							permissions: ["VIEW_TNC"],
						},
						{
							title: "Products",
							page: "/admin/products",
							icon: "flaticon-open-box",
							permissions: ["VIEW_PRODUCT"],
						},
						{
							title: "Uploads",
							page: "/admin/uploads",
							icon: "flaticon-user-settings",
							permissions: ["ADD_UPLOADS"],
						},
						{
							title: "Users",
							page: "/admin/users",
							icon: "flaticon-users",
							permissions: ["LOGGED_IN"],
						},
						{
							title: "Roles",
							page: "/admin/roles",
							icon: "flaticon-user-settings",
							permissions: ["USER_ROLE_MANAGEMENT"],
						},
					],
				},
				{
					title: "ARTH Portal",
					root: true,
					alignment: "left",
					permissions: ["VIEW_PORTAL"],
					submenu: [
						{
							title: "Cohorts",
							page: "/admin/cohorts",
							icon: "flaticon-user-settings",
							permissions: ["VIEW_COHORTS"],
						},
						{
							title: "PreQualifications",
							page: "/admin/preQualifications",
							icon: "flaticon-user-settings",
							permissions: ["VIEW_PREQUALIFICATION"],
						},
						{
							title: "AccountTransfer",
							page: "/admin/accountTransfer",
							icon: "flaticon-user-settings",
							permissions: ["ACCOUNT_TRANSFER"],
						},
						{
							title: "Customers",
							page: "/admin/customers",
							icon: "flaticon-user-settings",
							permissions: ["VIEW_CUSTOMERS"],
						},
						{
							title: "Payments",
							page: "/admin/payments",
							icon: "flaticon-user-settings",
							permissions: ["VIEW_PAYMENTS"],
						},
						{
							title: "Payment-advice",
							page: "/admin/payment-advice",
							icon: "flaticon-user-settings",
							permissions: ["VIEW_PAYMENT_ADVICE"],
						},
						{
							title: "Uploads",
							page: "/admin/partner-uploads",
							icon: "flaticon-user-settings",
							permissions: ["ADD_UPLOADS", "VIEW_COHORTS", "VIEW_CUSTOMERS", "VIEW_PAYMENTS"],
						},
						{
							title: "Paymint Device",
							page: "/admin/paymint-device",
							icon: "flaticon-user-settings",
							permissions: ["PAYMINT_DEVICE"],
						},
					],
				},
			],
		},
		aside: {
			self: {},
			items: [],
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
